import * as React from 'react';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const StackedAreaChartComponent = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart
        data={data}
        margin={{
          top: 10, right: 30, left: 0, bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" />
        <YAxis scale="sqrt" />
        <Tooltip />
        <Legend />
        <Area type="monotone" dataKey="filesChanged" stroke="#8884d8" fill="#8884d8" />
        <Area type="monotone" dataKey="alerts" stroke="#82ca9d" fill="#82ca9d" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default StackedAreaChartComponent;
