import React, { useEffect, useState } from "react";
import {epochSeconds, formatDate, getFileUrls, getStaticFileUrls, isHidden} from "../utils/Functions";
import Constants from "../utils/Constants";
import {Box, makeStyles, MenuItem, Select, Typography} from "@material-ui/core";
import {IconButton, Paper, Stack} from "@mui/material";
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import {getAccessToken} from "../utils/Auth";
import axios from "axios";
import Notification from "./Notification";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import {toast} from "react-toastify";
import DeleteDialog from "./DeleteDialog";
import ViewWeekOutlinedIcon from "@mui/icons-material/ViewWeekOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import ColumnsDialog from "./ColumnsDialog";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';


let http_str = "https://"
let analyzer_url = http_str.concat(Constants.apiHost, '.', Constants.domainName,'/api/v1/analyzers')
let activations_url = http_str.concat(Constants.apiHost, '.', Constants.domainName,'/api/v1/activationkeys')

const useStyles = makeStyles(theme => ({
    outer_div: {
        minHeight: '100vh',
    },
    itemBar: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#222222',
    },
    pageBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        backgroundColor: '#222222',
        color: '#AAAAAA',
    },
    tableComponent: {
        borderStyle: 'solid',
        borderColor: '#000',
        borderRadius: '10px',
        borderSpacing: '0px',
        borderRightWidth: '0px',
        borderTopWidth: '1px',
        borderBottomWidth: '0px',
        borderLeftWidth: '0px',
        // backgroundColor: '#222222' // setting this breaks row highlighting
    },
    tableCell: {
        paddingLeft: '12px',
    },
    clickableTableCell: {
        paddingLeft: '12px',
        cursor: 'pointer',
    },
    dialog: {
        backgroundColor: '#333333',
        color: '#CCCCCC',
        margin: '10',
    },
    dialogTitleBar: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogConfirm: {
        backgroundColor: '#149616',
        color: '#CCCCCC',
    },
    dialogNeutral: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogWarning: {
        backgroundColor: '#d42842',
        color: '#CCCCCC',
    },
    primaryColor: {
        color: '#000',
        backgroundColor: '#111'
    },
    whiteColor: {
        color: "#EEE",
    },
    redColor: {
        color: "#F00"
    },
    hide: {
        display: 'none',
    },
    spaced: {
        margin: '10px',
    }
}))

const toastOptions = {
    position: "bottom-right",
    autoClose: 6000,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true
}

let headers = [
    { field: 'pk', headerName: 'Code', width: 350, hidden: false },
    { field: 'created', headerName: 'Created', width: 220, hidden: false},
    { field: 'expiration', headerName: 'Expires', width: 220, hidden: false },
    { field: 'copy', headerName: 'Copy', width: 50, hidden: false },
    { field: 'delete', headerName: 'Delete', width: 50, hidden: false },
]

export const Settings = () => {
    const classes = useStyles()
    const [codes, setCodes] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedRow, setSelectedRow] = useState(null)
    const [columnsMode, setColumnsMode] = useState(false)
    const [addMode, setAddMode] = useState(false)
    const [limit, setLimit] = React.useState(10)
    const [pages, setPages] = useState([0])
    const [pageIndex, setPageIndex] = useState(0)
    const [winInstallerUrl, setWinInstallerUrl] = useState('')
    const [synInstallerUrl, setSynInstallerUrl] = useState('')
    const [ransomwareUrl, setRansomwareUrl] = useState('')
    const [isCopied, setIsCopied] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false)
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    //getFileUrls(analyzer_url, 'analyzer', 'windows', "", setInstallerUrl, setDeployCfn);
    //getFileUrls(analyzer_url, 'analyzer', 'ransomware', "", setRansomwareUrl, setDeployCfn);


    const getCodes = async () => {
        let url = activations_url
        if (pageIndex !== 0) {
            console.log('fetch page: ' + pages[pageIndex])
            url = url + '?limit=' + limit + '&nextPageKey=' + pages[pageIndex]
        }
        else {
            console.log('fetch page: 0')
            url = url + '?limit=' + limit
        }
        const http_headers = { "Content-Type": "application/json","Authorization": await getAccessToken() }
        console.log('url: ' + url)
        await axios
            .get(url, { headers: http_headers })
            .then(res => {
                if (pageIndex === pages.length - 1) {
                    let npk = res.data['nextPageKey']
                    if (npk) {
                        let tmp_arr = pages
                        tmp_arr.push(npk)
                        setPages(tmp_arr)
                    }
                }
                const codes = res.data['codes'];
                console.log(codes);
                setCodes(codes)
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err.request)
                } else {
                  console.log(err)
                }
                Notification(`Error while fetching ${url}. ${err}`, 'error');
                return Promise.reject(err);
            })
    }

    const addCode = async () => {
        let url = activations_url
        const http_headers = { 'Content-Type': 'application/json','Authorization': await getAccessToken() }
        const api = Constants.apiHost + '.' + Constants.domainName
        let params = {
            api_url: api
        }
        const toastId = toast.loading('Generating new Activation Code...', toastOptions)
        await axios
            .post(url, { 'activation_code': params },{ headers: http_headers })
            .then(res => {
                // Add the data submitted back to the local table so it is correct before server side refresh
                params.pk = res.data['pk']
                params.created = epochSeconds()
                setCodes(codes.concat([params]))
                toast.update(toastId, {render: `${res.data['msg']}`, type: "success", isLoading: false, autoClose: 2000})
                // Notification(res.data['msg'], 'info');
                getCodes()
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err)
                } else {
                  console.log(err)
                }
                toast.update(toastId, {render: `Error generating new Activation Code: ${err}`, type: "error", isLoading: false})
                // Notification(`Error: adding new site. ${err}`, 'error');
            })
    }

    const deleteCode = async (uuid) => {
        let url = activations_url + "/" + uuid
        const headers = { 'Content-Type': 'application/json','Authorization': await getAccessToken() }
        const toastId = toast.loading('deleting Activation Code...', toastOptions)
        await axios
            .delete(url, { headers: headers })
            .then(res => {
                console.log(res.data);
                let items_filtered = codes.map((x) => x)
                items_filtered = items_filtered.filter(i => i.pk !== uuid)
                setCodes(items_filtered)
                toast.update(toastId, {render: `${res.data['msg']}`, type: "success", isLoading: false, autoClose: 2000})
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err)
                } else {
                  console.log(err)
                }
                toast.update(toastId, {render: `Error deleting: ${err}`, type: "error", isLoading: false})
                return Promise.reject(err);
            })
    }

    const handleCopyCode = async (e, activation_code) => {
        // console.log(JSON.stringify(e))
        const toastId = toast.loading('copying Activation Code...', toastOptions)
        try {
            if (activation_code === 'undefined') {
                toast.update(toastId, {render: `copied code`, type: "success", isLoading: false, autoClose: 1000})
            }
            await navigator.clipboard.writeText(activation_code);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
            toast.update(toastId, {render: `copied code`, type: "success", isLoading: false, autoClose: 1000})
        } catch (error) {
            console.error("Failed to copy text: ", error);
        }
    };

    const handleOpenColumns = () => {
        setColumnsMode(true);
    };

    const handleCloseColumns = () => {
        setColumnsMode(false);
    };

     const handleAdd = () => {
        //let addData = addItem(formData)
        // check addData for error and notify user
        //setAddMode(false)
         addCode()
    }

    const handleOpenDelete = (e, uuid) => {
        // stopPropagation seems to block selecting and editing a row in 1 click, so remove it if it doesn't cause any other issues
        //e.stopPropagation()
        setSelectedRow(uuid)
        const item = codes.find(item => item.pk === uuid);
        setSelectedItem(item)
        setDeleteMode(true);
        console.log("delete " + selectedRow)
    };

    const handleDelete = (uuid) => {
        deleteCode(uuid)
        setDeleteMode(false);
    };

    const handleCloseDelete = () => {
        setDeleteMode(false);
    };
    
    const handleLimit = (event) => {
        setLimit(event.target.value);
        setPages([0])
        setPageIndex(0)
    };

    const handlePreviousPage = () => {
        if (pageIndex > 0) {
            setPageIndex(pageIndex - 1)
        }
    }

    const handleNextPage = () => {
        if (pageIndex < pages.length - 1) {
            setPageIndex(pageIndex + 1)
        }
    }

    const toggleColumn = (event) => {
        for (let i = 0; i < headers.length; i++) {
            if (headers[i]['field'] === event.target.id) {
                headers[i]['hidden'] = !headers[i]['hidden']
            }
        }
        //  ugly but since the checkboxes aren't tied to state, they are tied to column definitions, they don't update
        forceUpdate()
    };

    const isExpired = (exp_date) => {
        console.log("exp_date = " + exp_date)
        console.log("now = " + Math.floor(Date.now() / 1000))
        if (exp_date < Math.floor(Date.now() / 1000)) {
            console.log("expired")
            return false
        }
        else {
            console.log("valid")
            return true
        }
    }

    useEffect(() => {
        getCodes()
        getStaticFileUrls('rsanalyzer-installer-windows.zip', setWinInstallerUrl)
        //getStaticFileUrls('syn-installer.zip', setSynInstallerUrl)
        getStaticFileUrls('rsanalyzer-installer-windows.zip', setSynInstallerUrl)
        getStaticFileUrls('RansomwareSimulator.exe', setRansomwareUrl)
    }, []);

    return (
        <div className={classes.outer_div} style={{padding: '20px'}}>
            <Paper variant="outlined" style={{padding: '15px'}}>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    >
                        Global
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>There are not any global settings currently.</p>
                    </AccordionDetails>
                    <AccordionActions>
                    </AccordionActions>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                            Billing
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>Billing has been disabled for this account.
                            Please contact support@ransomstop.com if you have any questions</p>
                    </AccordionDetails>
                    <AccordionActions>
                        <Button>Cancel</Button>
                        <Button>Save</Button>
                    </AccordionActions>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3-content"
                      id="panel3-header"
                    >
                    Installation
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul>
                        <li>Activation Codes are required for installation on Windows and Synology NAS appliances</li>
                        <li>Copy the activation code and paste into the Installer when prompted</li>
                        </ul>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography variant="h4" >Activation Codes</Typography>
                            <Box>
                                <ViewWeekOutlinedIcon className={classes.spaced} color={"primary"}
                                              onClick={() => handleOpenColumns()}/>
                                <AddOutlinedIcon className={classes.spaced} color={"primary"}
                                         onClick={() => handleAdd()}/>
                            </Box>
                        </Stack>
                        {/* TABLE */}
                        <table className={classes.tableComponent} style={{width: 100 + "%"}} >
                            <thead>
                                <tr className={classes.tableComponent} >
                                    {headers.map(header => (
                                        <th width={header.width} className={ header.hidden === true ? classes.hide: '' }>{header.headerName}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody  className={classes.tableComponent} >
                            {codes.map(val =>
                                <tr key={val.pk} className={classes.tableComponent} style={{ color: isExpired(val.expiration) ? 'white' : '#d00429' }} >
                                    <td className={isHidden('pk', headers) ? classes.hide : classes.tableCell}>{val.pk}</td>
                                    <td className={isHidden('created', headers) ? classes.hide : classes.tableCell}>{formatDate(val.created)}</td>
                                    <td className={isHidden('expiration', headers) ? classes.hide : classes.tableCell}>{formatDate(val.expiration)}</td>
                                    <td className={isHidden('copy', headers) ? classes.hide : classes.clickableTableCell}>
                                        <ContentCopyOutlinedIcon color={"primary"}
                                                                 onClick={(e) => handleCopyCode(e, val.pk)}/>
                                    </td>
                                    <td className={isHidden('delete', headers) ? classes.hide : classes.clickableTableCell}>
                                        <BackspaceOutlinedIcon color={"error"}
                                                                 onClick={(e) => handleOpenDelete(e, val.pk)}/>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <Paper className={classes.itemBar} elevation={1}>
                            <Stack className={classes.pageBar}
                                   elevation={0}
                                   direction="row"
                                alignItems="center"
                                sx={{ width: 1, height: "5vh" }} >
                                    <IconButton onClick={() => getCodes()}><RefreshOutlinedIcon className={classes.spaced} /></IconButton>
                                    <Typography className={classes.spaced}>Rows per page:</Typography>
                                    <Select
                                        classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                                        labelId="fetch-limit-select"
                                        id="fetch-limit-select"
                                        value={limit}
                                        label="Limit"
                                        onChange={handleLimit}
                                    >
                                      <MenuItem value={10}>10</MenuItem>
                                      <MenuItem value={25}>25</MenuItem>
                                      <MenuItem value={50}>50</MenuItem>
                                    </Select>
                                    <IconButton disabled={pageIndex === 0} onClick={() => handlePreviousPage()}><ChevronLeftOutlinedIcon /></IconButton>
                                    <Typography variant={'h6'}>{pageIndex + 1}</Typography>
                                    <IconButton disabled={pageIndex >= pages.length - 1} onClick={() => handleNextPage()}><ChevronRightOutlinedIcon /></IconButton>
                            </Stack>
                        </Paper>
                        <p>For installation on other platforms, use the Deploy button from the <a href='/analyzers'
                            target='_blank"'>Analyzers tab<OpenInNewOutlinedIcon style={{verticalAlign: "bottom"}}/></a>, or <a href='https://support.plumesecurity.com' target='_blank"'>check
                            our documentation<OpenInNewOutlinedIcon style={{verticalAlign: "bottom"}}/></a> for the specific platform</p>
                    </AccordionDetails>
                    <AccordionActions>
                        <Button className={classes.dialogConfirm} onClick={() => window.open(synInstallerUrl, '_blank')} disabled={synInstallerUrl === '' ? true : false} variant="contained" startIcon={<CloudDownloadOutlinedIcon />}>SYNOLOGY INSTALLER</Button>
                        <Button className={classes.dialogConfirm} onClick={() => window.open(winInstallerUrl, '_blank')} disabled={winInstallerUrl === '' ? true : false} variant="contained" startIcon={<CloudDownloadOutlinedIcon />}>WINDOWS INSTALLER</Button>
                    </AccordionActions>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4-content"
                          id="panel4-header"
                        >
                            Extras
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>Downloadable Files</p>
                    </AccordionDetails>
                    <AccordionActions>
                        <Button className={classes.dialogConfirm} onClick={() => window.open(ransomwareUrl, '_blank')} disabled={ransomwareUrl === '' ? true : false} variant="contained" startIcon={<CloudDownloadOutlinedIcon />}>RANSOMWARE SIMULATOR</Button>
                    </AccordionActions>
                </Accordion>
                {/* DIALOGS */}
                {/* COLUMNS DIALOG */}
                <ColumnsDialog
                    variant={"outlined"}
                    open={columnsMode}
                    onClose={handleCloseColumns}
                    onChange={toggleColumn}
                    headers={headers}
                />
                {/* DELETE DIALOG */}
                <DeleteDialog
                    open={deleteMode}
                    onClose={handleCloseDelete}
                    onDelete={handleDelete}
                    //formData={formData}
                    //setFormData={setFormData}
                    type={'code'}
                    row={selectedRow}
                    item={selectedItem}
                />
            </Paper>
        </div>
    )
}
