import * as React from 'react';
import { ResponsiveContainer, ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const HeatMapComponent = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart
        layout="vertical"
        data={data}
        margin={{
          top: 20, right: 20, bottom: 20, left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis type="number" scale="sqrt" />
        <YAxis dataKey="time" type="category" />
        <Tooltip />
        <Legend />
        <Bar dataKey="filesChanged" barSize={20} fill="#413ea0" />
        <Bar dataKey="alerts" barSize={20} fill="#ff7300" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default HeatMapComponent;
