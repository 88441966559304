import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select } from "@material-ui/core";
import { Button, Typography, TextField, InputLabel, makeStyles } from "@material-ui/core";
import { formatDate, getTimeDeltaHuman } from "../utils/Functions";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { MenuItem, Switch } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { getAccessToken } from "../utils/Auth";
import axios from "axios";
import Notification from "./Notification";
import Constants from "../utils/Constants";
import { ValidatedTextField, anyErrors } from "../utils/ValidatedTextField";

let http_str = 'https://'
let list_url = http_str.concat(Constants.apiHost, '.', Constants.domainName, '/api/v1/lists')

const useStyles = makeStyles(theme => ({
    dialog: {
        backgroundColor: '#333333',
        color: '#CCCCCC',
        '& .MuiFormLabel-root.Mui-disabled': {
            color: 'red',
        },
    },
    dialogTitleBar: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogConfirm: {
        backgroundColor: '#149616',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#1be01e',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogNeutral: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#5861fc',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogWarning: {
        backgroundColor: '#d42842',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#fc0d31',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogWidget: {
        margin: '5px',
    },
    inputRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        verticalAlign: 'baseline',
        '& .MuiSelect-filled': {
            color: 'black',
            backgroundColor: '#555555',
        },
        '& .MuiSelect-iconFilled': {
            color: 'black',
        },
    },
    primaryColor: {
        color: '#000',
        backgroundColor: '#111'
    },
    whiteColor: {
        color: "#EEE",
    },
    hide: {
        display: 'none',
    },
    spaced: {
        margin: '10px',
    }
}))

export default function PoliciesDialog({ mode, open, onClose, onSave, item }) {
    const classes = useStyles()
    const [formData, setFormData] = useState({
        pk: null,
        policy_name: '',
        ransomware: '',
        ransomnote: '',
        magic_bytes: '',
        safe: '',
        blocked: '',
        enable_ransomnote_detection: true,
        enable_entropy_detection: false,
        action_suspend_user: false,
        action_suspend_access_key: false,
        action_block_ip: false,
        action_kill_process: false,
        action_delete_version: false, // Keeping this in case "Restore Files" is needed again
        action_enforce_reauth_sso: false, // New option
    })
    const [listData, setListData] = useState([])
    let title = 'Policies'

    const [error, setError] = useState([])
    const [isRequiredFieldEmpty, setIsRequiredFieldEmpty] = useState(true)

    if (mode === 'add') {
        title = 'Add Policy'
        item = null
    }
    else if (mode === 'edit') {
        title = 'Edit Policy: ' + item?.pk
    }

    const getLists = async () => {
        let url = list_url + '?limit=25'
        const http_headers = { "Content-Type": "application/json", "Authorization": await getAccessToken() }
        console.log('url: ' + url)
        await axios
            .get(url, { headers: http_headers })
            .then(res => {
                const items = res.data['lists'];
                console.log(items);
                setListData(items)
            })
            .catch(err => {
                if (err.response) {
                    console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                    console.log('no response from server: ' + err.request)
                } else {
                    console.log(err)
                }
                Notification(`Error while fetching ${url}. ${err}`, 'error');
            })
    }

    const handleChange = (event) => {
        const { name, value } = event.target
        console.log(name + ": " + value)
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
    };

    const handleCheckboxChange = (event) => {
        const { name } = event.target
        console.log(name + ": " + event.target.checked)
        setFormData((prevFormData) => ({ ...prevFormData, [name]: event.target.checked }))
    };

    const handleSubmit = (event) => {
        event.preventDefault()
        onSave(formData)
    }

    useEffect(() => {
        getLists()
        if (item) {
            setFormData({
                pk: item.pk,
                policy_name: item.policy_name,
                ransomware: item.ransomware,
                ransomnote: item.ransomnote,
                magic_bytes: item.magic_bytes,
                safe: item.safe,
                blocked: item.blocked,
                enable_ransomnote_detection: item.enable_ransomnote_detection,
                enable_entropy_detection: item.enable_entropy_detection,
                action_suspend_user: item.action_suspend_user,
                action_suspend_access_key: item.action_suspend_access_key,
                action_block_ip: item.action_block_ip,
                action_kill_process: item.action_kill_process,
                action_delete_version: item.action_delete_version, // Comment out if removing "Restore Files"
                action_enforce_reauth_sso: item.action_enforce_reauth_sso || false, // New field for SSO reauth
            })
            console.log('item: ' + JSON.stringify(item))
            console.log('formData: ' + formData)
        }
    }, [item]);

    useEffect(() => {
        if (formData.policy_name.trim() === '') {
            setIsRequiredFieldEmpty(true)
        } else {
            setIsRequiredFieldEmpty(false)
        }
    }, [formData.policy_name])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{ width: 600 }}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle className={classes.dialogTitleBar}>{title}</DialogTitle>
            <DialogContent className={classes.dialog}>
                <DialogContentText className={classes.dialog}>
                    <Grid container spacing={2}>
                        <Grid item xs={4} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Name:</InputLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <ValidatedTextField
                                id='name'
                                name='policy_name'
                                label='Name*'
                                value={formData.policy_name}
                                onChange={handleChange}
                                variant={"outlined"}
                                classes={{ root: classes.whiteColor }}
                                sx={{ width: 300 }}
                                inputProps={{ style: { color: "#FFF" } }}
                                InputLabelProps={{ style: { color: "#AAA" } }}
                                error={error}
                                setError={setError}
                                mode='default'
                                required='required'
                            />
                        </Grid>
                        <Grid item xs={4} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Safe List:</InputLabel>
                        </Grid>
                        <Grid item xs={7} className={classes.inputRow}>
                            <Select
                                name="safe"
                                label="Safe Extensions"
                                variant="outlined"
                                value={formData.safe}
                                onChange={handleChange}
                                classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                                style={{ minWidth: 225 }}
                            >
                                <MenuItem key='0' value=''>none</MenuItem>
                                {listData.map((item) =>
                                    <MenuItem key={item.pk} value={item.pk}>{item.list_name}</MenuItem>)}
                            </Select>
                        </Grid>
                        <Grid item xs={4} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Blocked List:</InputLabel>
                        </Grid>
                        <Grid item xs={7} className={classes.inputRow}>
                            <Select
                                name="blocked"
                                label="Blocked Extensions"
                                variant="outlined"
                                value={formData.blocked}
                                onChange={handleChange}
                                classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                                style={{ minWidth: 225 }}
                            >
                                <MenuItem key='0' value=''>none</MenuItem>
                                {listData.map((item) =>
                                    <MenuItem key={item.pk} value={item.pk}>{item.list_name}</MenuItem>)}
                            </Select>
                        </Grid>
                        <Grid item xs={3} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Ransom Note Detection:</InputLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.inputRow}>
                            <Switch
                                name="enable_ransomnote_detection"
                                checked={formData.enable_ransomnote_detection}
                                onChange={handleCheckboxChange}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Stop Processes:</InputLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.inputRow}>
                            <Switch
                                name="action_kill_process"
                                checked={formData.action_kill_process}
                                onChange={handleCheckboxChange}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Suspend User Account:</InputLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.inputRow}>
                            <Switch
                                name="action_suspend_user"
                                checked={formData.action_suspend_user}
                                onChange={handleCheckboxChange}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Suspend Access Key:</InputLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.inputRow}>
                            <Switch
                                name="action_suspend_access_key"
                                checked={formData.action_suspend_access_key}
                                onChange={handleCheckboxChange}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Block IP</InputLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.inputRow}>
                            <Switch
                                name="action_block_ip"
                                checked={formData.action_block_ip}
                                onChange={handleCheckboxChange}
                            />
                        </Grid>
                        {/* Commented out "Restore Files" option */}
                        {/* <Grid item xs={3} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Restore Files</InputLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.inputRow}>
                            <Switch
                                name="action_delete_version"
                                checked={formData.action_delete_version}
                                onChange={handleCheckboxChange}
                            />
                        </Grid> */}
                        <Grid item xs={3} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Enforce Reauth to SSO</InputLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.inputRow}>
                            <Switch
                                name="action_enforce_reauth_sso"
                                checked={formData.action_enforce_reauth_sso}
                                onChange={handleCheckboxChange}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='subtitle2' className={classes.dialogWidget} component={'span'}>Created: {formatDate(item ? item.created : new Date())} ({getTimeDeltaHuman(item ? item.created : new Date())})</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='subtitle2' className={classes.dialogWidget} component={'span'}>Modified: {formatDate(item ? item.modified : new Date())} ({getTimeDeltaHuman(item ? item.modified : new Date())})</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='subtitle2' className={classes.dialogWidget} component={'span'}>Used: {item ? item.ref_count : 0}</Typography>
                        </Grid>
                    </Grid>
                </DialogContentText>
                <DialogActions>
                    <Button className={classes.dialogNeutral} onClick={() => { setError([]); onClose() }}>Cancel</Button>
                    <Button className={classes.dialogConfirm} onClick={(event) => handleSubmit(event)} variant="contained" startIcon={<CheckBoxOutlinedIcon />} disabled={!anyErrors(error) || isRequiredFieldEmpty}>SAVE</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}
