import React from "react";
import Constants from "../utils/Constants";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Button, Typography, makeStyles } from "@material-ui/core";
import Grid from '@mui/material/Unstable_Grid2';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';

const http_str = "https://"
const object_url = http_str.concat(Constants.apiHost, '.', Constants.domainName,'/api/v1/sites')
let base_url = ''
let help_url = "https://support.plumesecurity.com/portal/en/kb/articles/integrating-ransomstop-with-active-directory"


const useStyles = makeStyles(theme => ({
    dialog: {
        backgroundColor: '#333333',
        color: '#CCCCCC',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        '& .MuiFormLabel-root.Mui-disabled': {
            color: 'red',
        },
        '& .MuiPaper-root': {
            backgroundColor: '#333333',
        }
    },
    dialogTitleBar: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogConfirm: {
        backgroundColor: '#149616',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#1be01e',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogNeutral: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#5861fc',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogWarning: {
        backgroundColor: '#d42842',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#fc0d31',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogWidget: {
        margin: '5px',
        color: '#CCCCCC',
        justify: 'flex',
        alignItems: 'center',
    },
    inputRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        verticalAlign: 'baseline',
    },
    accordian: {
        color: '#EEE',
        justifyContent: "unset",
    },
    primaryColor: {
        color: '#000',
        backgroundColor: '#111'
    },
    whiteColor: {
        color: "#EEE",
    },
    hide: {
        display: 'none',
    },
    spaced: {
        margin: '10px',
    }
}))

export default function DeployDialogWindows({ open, onClose, installer_url, item }) {
    const classes = useStyles()

    let title = 'Deploy AD Site ' + item?.pk
    base_url = object_url

    return (
        <Dialog
            open={open}
            onClose={onClose}
            // id={item?.pk}
            sx={{
                width: 500
              }}
            fullWidth={true}
            maxWidth = "sm"
        >
            <DialogTitle className={classes.dialogTitleBar}>{title}</DialogTitle>
            <DialogContent className={classes.dialog}>
                <DialogContentText className={classes.dialog} component={'span'}>
                    <Grid container spacing={2}>
                        <Grid item xs={11} className={classes.inputRow}>
                            <Typography className={classes.dialog} component={'span'}>
                                To deploy RansomStop in Active Directory:<br />
                                <ol>
                                    <li>Download the installer to a Domain Controller</li>
                                    <li>Unzip the installer package</li>
                                    <li>Open Setup.pdf and follow the instructions</li>
                                </ol>
                                For detailed instructions:
                                <div><a href={help_url} target="_blank">Instructions To Install RansomStop in Active Directory</a></div>
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContentText>
                <DialogActions>
                    <Button className={classes.dialogConfirm} onClick={() => window.open(installer_url, '_blank')} disabled={installer_url === '' ? true : false} variant="contained" startIcon={<CloudDownloadOutlinedIcon />}>INSTALLER</Button>
                    <Button className={classes.dialogNeutral} onClick={() => onClose()} variant="contained" startIcon={<DisabledByDefaultOutlinedIcon />}>CLOSE</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

