import React, {useEffect, useState} from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { Button, Typography, TextField, InputLabel, makeStyles } from "@material-ui/core";
import {formatDate, getTimeDeltaHuman} from "../utils/Functions";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import Grid from '@mui/material/Unstable_Grid2';
import Checkbox from '@mui/material/Checkbox';


const useStyles = makeStyles(theme => ({
    dialog: {
        backgroundColor: '#333333',
        color: '#CCCCCC',
        '& .MuiFormLabel-root.Mui-disabled': {
            color: 'red',
        },
    },
    dialogTitleBar: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogConfirm: {
        backgroundColor: '#149616',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#1be01e',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogNeutral: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#5861fc',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogWarning: {
        backgroundColor: '#d42842',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#fc0d31',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogWidget: {
        margin: '5px',
    },
    inputRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        verticalAlign: 'baseline',
        '& .MuiSelect-filled': {
            color: 'black',
            backgroundColor: '#555555',
        },
        '& .MuiSelect-iconFilled': {
            color: 'black',
        },
    },
    divider: {
      background: '#bbbbbb',
        border: '1px solid',
        width: '95%',
    },
    primaryColor: {
        color: '#000',
        backgroundColor: '#111'
    },
    whiteColor: {
        color: "#EEE",
    },
    hide: {
        display: 'none',
    },
    spaced: {
        margin: '10px',
    }
}))

export default function AlertDialog({ open, onClose, onSave, item}) {
    const classes = useStyles()
    const [formData, setFormData] = useState({
        pk: '',
        alert_type: '',
        title: '',
        description: '',
        notes: '',
        file: {},
        confidence: 0,
        local_ip: '',
        action_user_suspended: false,
        action_access_key_suspended: false,
        action_ip_blocked: false,
        action_process_killed: false,
        action_file_restored: false,
        acked: false,
    })
    let title = 'Alert: ' + item?.pk

    function optional_fields(alert) {

        return (
            <>
                <Grid item xs={2} className={classes.inputRow}>
                    <InputLabel className={classes.dialog}>Location:</InputLabel>
                </Grid>
                <Grid item xs={5} className={classes.inputRow}>
                    <Typography className={classes.dialog}>{ alert?.file.parent_folder }</Typography>
                </Grid>
                <Grid item xs={2} className={classes.inputRow}>
                    <InputLabel className={classes.dialog}>Region:</InputLabel>
                </Grid>
                <Grid item xs={3} className={classes.inputRow}>
                    <Typography className={classes.dialog}>{alert?.region}</Typography>
                </Grid>
                <Grid item xs={2} className={classes.inputRow}>
                    <InputLabel className={classes.dialog}>User ID:</InputLabel>
                </Grid>
                <Grid item xs={9} className={classes.inputRow}>
                    <Typography className={classes.dialog}>{alert?.user_id}</Typography>
                </Grid>
                <Grid item xs={2} className={classes.inputRow}>
                    <InputLabel className={classes.dialog}>Source IP:</InputLabel>
                </Grid>
                <Grid item xs={9} className={classes.inputRow}>
                    <Typography className={classes.dialog}>{alert?.source_ip}</Typography>
                </Grid>
            </>
        )
    }

    const handleChange = (event) => {
        const { name, value } = event.target
        console.log(name + ": " + value)
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
	};

    const handleCheckboxChange = (event) => {
        const { name } = event.target
        console.log(name + ": " + event.target.checked)
        setFormData((prevFormData) => ({ ...prevFormData, [name]: event.target.checked }))
	};

    const handleSubmit = (event) => {
        event.preventDefault()
        onSave(formData)
    }

    useEffect(() => {
        if (item) {
            setFormData({
                pk: item.pk,
                alert_type: item.alert_type,
                title: item.title,
                description: item.description,
                notes: item.notes,
                fut: item.fut,
                confidence: item.confidence,
                local_ip: item.local_ip,
                action_user_suspended: item.action_user_suspended,
                action_access_key_suspended: item.action_access_key_suspended,
                action_ip_blocked: item.action_ip_blocked,
                action_process_killed: item.action_process_killed,
                action_file_restored: item.action_file_restored,
                acked: item.acked,
            })
            console.log('item: ' + JSON.stringify(item))
            console.log('formData: ' + formData)
        }
    }, [item]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                width: 600
              }}
            fullWidth={true}
            maxWidth = "sm"
        >
            <DialogTitle className={classes.dialogTitleBar}>{title}</DialogTitle>
            <DialogContent className={classes.dialog}>
                <DialogContentText className={classes.dialog}>
                    <Grid container spacing={2}>
                        <Grid item xs={8} className={classes.inputRow}>
                            <Typography className={classes.dialog}>{item?.title}</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Acknowledged</InputLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.inputRow}>
                            <Checkbox
                                name='acked'
                                label='Acknowledged'
                                checked={formData.acked}
                                onChange={handleCheckboxChange}
                                variant={"outlined"}
                                className={classes.dialog}
                                classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                                style={{minWidth: 55}}
                            />
                        </Grid>
                        <Divider variant="middle" classes={{root: classes.divider}} />
                        <Grid item xs={11} className={classes.inputRow}>
                            <Typography className={classes.dialog}>Actions Taken:</Typography>
                        </Grid>
                        <Grid item xs={3} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>IP Blocked</InputLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.inputRow}>
                            <Checkbox
                                name='action_ip_blocked'
                                label='IP Blocked'
                                checked={formData.action_ip_blocked}
                                variant={"outlined"}
                                //className={classes.dialog}
                                classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                                style={{minWidth: 55}}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>User Suspended</InputLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.inputRow}>
                            <Checkbox
                                name='action_suspend_user'
                                label='User Suspended'
                                checked={formData.action_user_suspended}
                                variant={"outlined"}
                                //className={classes.dialog}
                                classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                                  style={{minWidth: 55}}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Process Stopped</InputLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.inputRow}>
                            <Checkbox
                                name='action_process_killed'
                                label='Process Stopped'
                                defaultValue={false}
                                checked={formData.action_process_killed}
                                variant={"outlined"}
                                //className={classes.dialog}
                                classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                                  style={{minWidth: 55}}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Access Key Suspended</InputLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.inputRow}>
                            <Checkbox
                                name='action_suspend_access_key'
                                label='Access Key Suspended'
                                defaultValue={false}
                                checked={formData.action_access_key_suspended}
                                variant={"outlined"}
                                //className={classes.dialog}
                                classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                                  style={{minWidth: 55}}
                            />
                        </Grid>
                        <Divider variant="middle" classes={{root: classes.divider}} />
                        <Grid item xs={2} className={classes.inputRow}>
                            <Typography className={classes.dialog}>Confidence:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography>{item?.confidence}</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.inputRow}>
                            <Typography className={classes.dialog}>Alert Type:</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>{item?.alert_type}</Typography>
                        </Grid>
                        {optional_fields(formData.fut)}
                        <Grid item xs={2} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Description:</InputLabel>
                        </Grid>
                        <Grid item xs={9} className={classes.inputRow}>
                            <Typography>{item?.description}</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Notes:</InputLabel>
                        </Grid>
                        <Grid item xs={9} className={classes.inputRow}>
                            <TextField
                                multiline
                                fullWidth
                                id='notes'
                                name='notes'
                                label='Notes'
                                value={formData.notes}
                                onChange={handleChange}
                                variant={"outlined"}
                                //className={classes.dialog}
                                classes={{ root: classes.whiteColor }}
                                sx={{
                                    width: 500
                                  }}
                                inputProps={{ style: { color: "#FFF" } }}
                                InputLabelProps={{ style: { color: "#AAA" } }}
                            />
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant='subtitle2' className={classes.dialogWidget} component={'span'}>Created: {formatDate(item ? item.created : new Date().getTime() / 1000)} ({getTimeDeltaHuman(item ? item.created : new Date())})</Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant='subtitle2' className={classes.dialogWidget} component={'span'}>Modified: {formatDate(item ? item.modified : new Date().getTime() / 1000)} ({getTimeDeltaHuman(item ? item.modified : new Date())})</Typography>
                        </Grid>
                    </Grid>
                    </DialogContentText>
                <DialogActions>
                    <Button className={classes.dialogNeutral} onClick={() => onClose()}>Cancel</Button>
                    <Button className={classes.dialogConfirm} onClick={(event) => handleSubmit(event)} variant="contained" startIcon={<CheckBoxOutlinedIcon />}>SAVE</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}
