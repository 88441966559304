import React from 'react';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';


export function ValidatedTextField({ required=null, mode=null, value, onChange, maxLength = 32, error, setError, hoverText, ...props }) {
  
  const handleVChange = (e) => {
    let newValue = e.target.value;
    const newValueName = e.target.name;

    console.log('name=' + newValueName)
    console.log('error array: ' + JSON.stringify(error))

    const updatedError = { ...error };

    if ( required === 'required' &&
      (newValue === '' || newValue === null) ) {
        updatedError[newValueName] = 'Required field'
    } else if (!required &&
      (newValue === '' || newValue === null) ) {
        updatedError[newValueName] = ''
    }  else if( newValue.length > maxLength) {
            updatedError[newValueName] = `Max Length is ${maxLength}, please delete extra characters.`
    } else if ( mode === 'default' && 
          !/^([a-zA-Z0-9_-]+\s)*[a-zA-Z0-9_-]+$/.test(newValue)) {
          updatedError[newValueName] = 'Input can only contain letters, single space, underscores (_), or hyphens (-).'  
    } else if ( mode === 'default-1' && 
      !/^[a-zA-Z0-9_-]+$/.test(newValue)) {
        updatedError[newValueName] = 'Input can only contain letters,underscores (_), or hyphens (-).'  
    } else if ( mode === 'aws-account-id' && 
      !/^\d{12}$/.test(newValue)) {
           updatedError[newValueName] = 'AWS account ID is a 12-digit number.'
    } else if ( mode === 'gcp-project-id' &&
      !/^[a-zA-Z][-a-zA-Z0-9]{4,28}[a-zA-Z0-9]{1}$/.test(newValue)) {
           updatedError[newValueName] = 'GCP Project ID is 6-30 alphanumeric + hyphens'
    } else if ( mode === 'ad-domain-name' &&
      !/^[-_a-zA-Z0-9]{4,28}\.[a-z]+$/.test(newValue)) {
           updatedError[newValueName] = 'AD Domain name is <company>.<top level domain>'
    } else if (mode === 'aws-region' &&
        !/^[a-z]{2}-[a-z]+-[1-9][0-9]?$/.test(newValue)){
          updatedError[newValueName] = 'AWS Region format Invalid.'
    } else if (mode === 'gcp-region' &&
        !/^[a-zA-Z]{2,12}-[a-zA-Z]{4,9}[1-9][0-9]?$/.test(newValue)){
          updatedError[newValueName] = 'GCP Region format Invalid.'
    } else if ( mode === 'aws-vpc-id' &&
        !/^vpc-[a-z0-9]{8,17}$/.test(newValue)){
          updatedError[newValueName] = 'AWS VPC ID is vpc-(alphanumeric characters).'
    } else if ( mode === 'aws-subnet-id' &&
        !/^(subnet-[0-9a-fA-F]{8,17})(\nsubnet-[0-9a-fA-F]{8,17}){0,2}$/.test(newValue) ) {
          updatedError[newValueName] = 'AWS Subnet ID, 3x, one on each row. subnet-(alpha char).'
    } else if ( mode === 'log-interval' &&
        !/^\d{1,4}$/.test(newValue) ) {
          updatedError[newValueName] = 'Invalid format.'
    }else {
      updatedError[newValueName] = ''
    }

    setError(updatedError);

    console.log('name=' + newValueName);
    console.log('error object: ' + JSON.stringify(updatedError));

    if (onChange) {
      onChange(e);
    }

  };

  return (
      <Tooltip placement="right" title={hoverText}>
          <TextField
            {...props}
            value={value}
            onChange={handleVChange}
            error={!!error[props.name]?.length}
            helperText={!!error[props.name]?.length ? error[props.name]: ''}
            inputProps={{ maxLength }}
          />
      </Tooltip>
  );
};


export function anyErrors(obj, isRequired) {
  // console.log('object = ' + JSON.stringify(obj))
  return Object?.values(obj).every(value => !value.trim());
}



