// src/LineChartComponent.js
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const LineChartComponent = ({ data }) => {
  return (
    <LineChart width={800} height={400} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="time" />
      <YAxis yAxisId="left" label={{ value: "files changed", angle: -90, stroke: "#8884d8", position: 'insideLeft' }} />
        <YAxis yAxisId="right" orientation="right" label={{ value:"alerts", angle: -90, stroke: "#82ca9d", position: 'insideRight' }} />
      <Tooltip />
      <Legend />
      <Line yAxisId="left" type="monotone" dataKey="filesChanged" stroke="#8884d8" />
      <Line yAxisId="right" type="monotone" dataKey="alerts" stroke="#82ca9d" />
    </LineChart>
  );
};

export default LineChartComponent;
