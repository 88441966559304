import React, { useEffect, useState } from "react";
import axios from "axios";
import { getAccessToken } from "../utils/Auth";
import Constants from "../utils/Constants";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Button, Typography, TextField, InputLabel, Select, makeStyles } from "@material-ui/core";
import { IconButton } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

const http_str = "https://"
const site_url = http_str.concat(Constants.apiHost, '.', Constants.domainName,'/api/v1/sites')
const analyzer_url = http_str.concat(Constants.apiHost, '.', Constants.domainName,'/api/v1/analyzers')
let base_url = ''
// let cfn_base_url = 'https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create/review?stackName=' + Constants.applicationName + 'SitePolicies&templateURL='


const useStyles = makeStyles(theme => ({
    dialog: {
        backgroundColor: '#333333',
        color: '#CCCCCC',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        '& .MuiFormLabel-root.Mui-disabled': {
            color: 'red',
        },
        '& .MuiPaper-root': {
            backgroundColor: '#333333',
        }
    },
    dialogTitleBar: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogConfirm: {
        backgroundColor: '#149616',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#1be01e',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogNeutral: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#5861fc',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogWarning: {
        backgroundColor: '#d42842',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#fc0d31',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogWidget: {
        margin: '5px',
        color: '#CCCCCC',
        justify: 'flex',
        alignItems: 'center',
    },
    inputRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        verticalAlign: 'baseline',
    },
    accordian: {
        color: '#EEE',
        justifyContent: "unset",
    },
    primaryColor: {
        color: '#000',
        backgroundColor: '#111'
    },
    whiteColor: {
        color: "#EEE",
    },
    hide: {
        display: 'none',
    },
    spaced: {
        margin: '10px',
    }
}))

export default function DeployDialog({ type, open, onClose, href, cfn, item }) {
    const classes = useStyles()
    let title = 'Deploy'

    if (type === 'site') {
        title = 'Deploy Site Policies to ' + item?.site_name
        base_url = site_url
    }
    else if (type === 'analyzer') {
        title = 'Deploy Analyzer ' + item?.pk
        base_url = analyzer_url
    }

    const getButtons = () => {
        if (type === 'site' || type === 'analyzer') {
            if (item?.site_type === 'AWS' || item?.monitored_storage_type === 'S3') {
                return (
                    <>
                        <Button className={classes.dialogConfirm} onClick={() => window.open(href, '_blank')} disabled={href === '' ? true : false} variant="contained" startIcon={<CloudDownloadOutlinedIcon />}>DOWNLOAD</Button>
                        <Button className={classes.dialogConfirm} onClick={() => window.open(cfn, '_blank')} disabled={cfn === ''} variant="contained" startIcon={<CloudUploadOutlinedIcon />}>PROCEED TO AWS</Button>
                        <Button className={classes.dialogNeutral} onClick={() => onClose()} variant="contained" startIcon={<DisabledByDefaultOutlinedIcon />}>CLOSE</Button>
                    </>
                )
            }
            else if (item?.site_type === 'GCP') {
                return (
                    <>
                        <Button className={classes.dialogConfirm} onClick={() => window.open(href, '_blank')} disabled={href === '' ? true : false} variant="contained" startIcon={<CloudDownloadOutlinedIcon />}>GCP DOWNLOAD</Button>
                        <Button className={classes.dialogConfirm} onClick={() => window.open(cfn, '_blank')} disabled={cfn === ''} variant="contained" startIcon={<CloudUploadOutlinedIcon />}>PROCEED TO GCP</Button>
                        <Button className={classes.dialogNeutral} onClick={() => onClose()} variant="contained" startIcon={<DisabledByDefaultOutlinedIcon />}>CLOSE</Button>
                    </>
                )
            }
            else if (item?.site_type === 'AD') {
                return (
                    <>
                        <Button className={classes.dialogConfirm} onClick={() => window.open(href, '_blank')} disabled={href === '' ? true : false} variant="contained" startIcon={<CloudDownloadOutlinedIcon />}>GCP DOWNLOAD</Button>
                        <Button className={classes.dialogConfirm} onClick={() => window.open(cfn, '_blank')} disabled={cfn === ''} variant="contained" startIcon={<CloudUploadOutlinedIcon />}>PROCEED TO GCP</Button>
                        <Button className={classes.dialogNeutral} onClick={() => onClose()} variant="contained" startIcon={<DisabledByDefaultOutlinedIcon />}>CLOSE</Button>
                    </>
                )
            }
            else {
                return (
                    <>
                        <Button className={classes.dialogNeutral} onClick={() => onClose()} variant="contained" startIcon={<DisabledByDefaultOutlinedIcon />}>CLOSE</Button>
                    </>
                )
            }
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            // id={item?.pk}
            sx={{
                width: 500
              }}
            fullWidth={true}
            maxWidth = "sm"
        >
            <DialogTitle className={classes.dialogTitleBar}>{title}<IconButton><HelpOutlineOutlinedIcon /></IconButton></DialogTitle>
            <DialogContent className={classes.dialog}>
                <DialogContentText className={classes.dialog} component={'span'}>
                    <Grid container spacing={2}>
                        <Grid item xs={11} className={classes.inputRow}>
                            <Typography className={classes.dialog} component={'span'}>
                                To deploy automatically, you will be redirected to the AWS console.
                                It is best if you are already logged into the correct AWS account in
                                your browser.
                                <div><a href='https://support.plumesecurity.com/portal/en/kb/articles/configure-a-site' target="_blank">Instructions To Deploy Through AWS Console</a></div>
                            </Typography>
                        </Grid>
                        <Grid item xs={11} className={classes.inputRow}>
                            <Typography className={classes.dialog} component={'span'}>OR
                            </Typography>
                        </Grid>
                        <Grid item xs={11} className={classes.inputRow}>
                            <Typography className={classes.dialog} component={'span'}>
                                You can download the CloudFormation template and install it manually.
                                <div><a href='https://support.plumesecurity.com/portal/en/kb/articles/configure-a-site' target="_blank">Instructions To Download and Manually Deploy</a></div>
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContentText>
                <DialogActions>
                    { getButtons() }
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

