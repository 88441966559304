import React, { useEffect, useState } from "react";
import { formatDate, getTimeDeltaHuman, getRandom } from "../utils/Functions";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Button, Typography, InputLabel, Select, makeStyles } from "@material-ui/core";
import { MenuItem, IconButton } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { ValidatedTextField, anyErrors } from "../utils/ValidatedTextField";


const useStyles = makeStyles(theme => ({
    dialog: {
        backgroundColor: '#333333',
        color: '#CCCCCC',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        '& .MuiFormLabel-root.Mui-disabled': {
            color: 'red',
        },
        '& .MuiPaper-root': {
            backgroundColor: '#333333',
        }
    },
    dialogTitleBar: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogConfirm: {
        backgroundColor: '#149616',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#1be01e',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogNeutral: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#5861fc',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogWarning: {
        backgroundColor: '#d42842',
        color: '#CCCCCC',
        '&:hover': {
            backgroundColor: '#fc0d31',
            color: '#333333',
        },
        '&:disabled': {
            color: '#CCCCCC',
        }
    },
    dialogWidget: {
        margin: '5px',
        color: '#CCCCCC',
        justify: 'flex',
        alignItems: 'center',
    },
    inputRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        verticalAlign: 'baseline',
    },
    accordian: {
        color: '#EEE',
        justifyContent: "unset",
    },
    primaryColor: {
        color: '#000',
        backgroundColor: '#111'
    },
    whiteColor: {
        color: "#EEE",
    },
    hide: {
        display: 'none',
    },
    spaced: {
        margin: '10px',
    }
}))


export default function SitesDialog({ open, onClose, onSave, formData, setFormData, requiresSave, setRequiresSave}) {
    const classes = useStyles()
    let title = 'Sites'
    
    const [error, setError] = useState([])
    const [isRequiredFieldEmpty, setIsRequiredFieldEmpty] = useState(true)

    if (formData.pk) {
        title = 'Edit Site: ' + formData.pk
    } else {
        title = 'Add Site'
        if (formData.site_type === 'GCP') {
            formData.aws_account_id = 'plumesec-' + getRandom('loweralpha', 4)
        }
        else {
            formData.aws_account_id = ''
        }
    }

    function dynamic_fields(type) {
        // This is for site specific fields, i.e. AWS vs GCP vs Onprem
        if (type === 'AWS') {
            return (
                <>
                    <Grid item xs={4} className={classes.inputRow}>
                        <InputLabel className={classes.dialog}>Account ID</InputLabel>
                    </Grid>
                    <Grid item xs={7}>
                        <ValidatedTextField
                            id='aws_account_id'
                            name='aws_account_id'
                            label='Account ID*'
                            value={formData.aws_account_id}
                            onChange={handleChange}
                            variant={"outlined"}
                            className={classes.dialog}
                            sx={{ width: 200 }}
                            inputProps={{ style: { color: "#FFF" } }}
                            InputLabelProps={{ style: { color: "#AAA" } }}
                            error={error}
                            setError={setError}
                            maxLength={12}
                            mode='aws-account-id'
                            required='required'
                        />
                    </Grid>
                </>
            )
        }
        else if (type === 'GCP') {
            return (
                <>
                    <Grid item xs={4} className={classes.inputRow}>
                        <InputLabel className={classes.dialog}>Project ID</InputLabel>
                    </Grid>
                    <Grid item xs={7}>
                        <ValidatedTextField
                            id='aws_account_id'
                            name='aws_account_id'
                            label='Project ID*'
                            value={formData.aws_account_id}
                            onChange={handleChange}
                            variant={"outlined"}
                            className={classes.dialog}
                            sx={{ width: 200 }}
                            inputProps={{ style: { color: "#FFF" } }}
                            InputLabelProps={{ style: { color: "#AAA" } }}
                            error={error}
                            setError={setError}
                            maxLength={30}
                            mode='gcp-project-id'
                            required='required'
                        />
                    </Grid>
                </>
            )
        }
        else if (type === 'AD') {
            return (
                <>
                    <Grid item xs={4} className={classes.inputRow}>
                        <InputLabel className={classes.dialog}>Domain name</InputLabel>
                    </Grid>
                    <Grid item xs={7}>
                        <ValidatedTextField
                            id='aws_account_id'
                            name='aws_account_id'
                            label='AD Domain Name*'
                            value={formData.aws_account_id}
                            onChange={handleChange}
                            variant={"outlined"}
                            className={classes.dialog}
                            sx={{ width: 200 }}
                            inputProps={{ style: { color: "#FFF" } }}
                            InputLabelProps={{ style: { color: "#AAA" } }}
                            error={error}
                            setError={setError}
                            maxLength={30}
                            mode='ad-domain-name'
                            required='required'
                        />
                    </Grid>
                </>
            )
        }
        else {
            return (
                <></>
            )
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        console.log(event.target)
        setRequiresSave(true)
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        
        // If the type is changed to "Standalone", clear the account ID
        if (name === 'type' && value === 'Standalone') {
            setFormData((prevFormData) => ({ ...prevFormData, aws_account_id: '' }));
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        onSave(formData);
    }

    useEffect(() => {
        console.log('formData: ' + JSON.stringify(formData))
        if (formData.site_name.trim() === '' || (
            formData.site_type !== 'Standalone' &&
            formData.aws_account_id.trim() === '')) {
                setIsRequiredFieldEmpty(true);
        } else {
            setIsRequiredFieldEmpty(false);
        }
    }, [formData.site_name, formData.aws_account_id, formData.site_type]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{ width: 500 }}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle className={classes.dialogTitleBar}>
                {title}
                <IconButton><HelpOutlineOutlinedIcon /></IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialog}>
                <DialogContentText className={classes.dialog}>
                    <Grid container spacing={2}>
                        <Grid item xs={4} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Name</InputLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <ValidatedTextField
                                id='site_name'
                                name='site_name'
                                label='Name*'
                                value={formData.site_name}
                                onChange={handleChange}
                                variant={"outlined"}
                                classes={{ root: classes.whiteColor }}
                                sx={{ width: 300 }}
                                inputProps={{ style: { color: "#FFF" } }}
                                InputLabelProps={{ style: { color: "#AAA" } }}
                                error={error}
                                setError={setError}
                                mode='default'
                                required='required'
                            />
                        </Grid>
                        <Grid item xs={4} className={classes.inputRow}>
                            <InputLabel className={classes.dialog}>Type</InputLabel>
                        </Grid>
                        <Grid item xs={7} className={classes.inputRow}>
                            <Select
                                id='site_type'
                                name='site_type'
                                label="Type"
                                variant="outlined"
                                value={formData.site_type}
                                onChange={handleChange}
                                classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                                style={{ minWidth: 200 }}
                            >
                                <MenuItem value={'Standalone'}>Standalone</MenuItem>
                                <MenuItem value={'AD'}>ActiveDirectory</MenuItem>
                                <MenuItem value={'AWS'}>AWS</MenuItem>
                                <MenuItem value={'GCP'}>Google Cloud</MenuItem>
                            </Select>
                        </Grid>
                        {dynamic_fields(formData.site_type)}
                        <Grid item xs={11} className={classes.inputRow}></Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={11}>
                            <Typography variant='subtitle2' className={classes.dialogWidget} component={'span'}>
                                Created: {formatDate(formData.created ? formData.created : new Date())}  ({getTimeDeltaHuman(formData.created ? formData.created : new Date())})
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant='subtitle2' className={classes.dialogWidget} component={'span'}>
                                Modified: {formatDate(formData.modified ? formData.modified : new Date())} ({getTimeDeltaHuman(formData.modified ? formData.modified : new Date())})
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant='subtitle2' className={classes.dialogWidget} component={'span'}>
                                Used: {formData.ref_count ? formData.ref_count : 0}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContentText>
                <DialogActions>
                    <Button className={classes.dialogNeutral} onClick={() => { setError([]); onClose() }} variant="contained" startIcon={<DisabledByDefaultOutlinedIcon />}>
                        Cancel
                    </Button>
                    <Button className={classes.dialogConfirm} onClick={handleSubmit} variant="contained" startIcon={<CheckBoxOutlinedIcon />} disabled={!anyErrors(error) || isRequiredFieldEmpty || ! requiresSave}>
                        SAVE
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}
